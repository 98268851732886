import React                     from 'react'
import {connect}                 from 'react-redux'
import {AppData, healConfig}     from '../app-data'
import {MenuBar, Menu, MenuItem} from '../ui/menu'
import {MenuButton}              from '../ui/buttons'
import {getConfig, updateConfig} from '../stores/app'
// import Fade                    from '../ui/animation/fade'

const MenuBarContainer = ({name, config, updateConfig}) => {
    // const [show, setShow] = useState(true);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShow(true)
    //     }, 200);
    //     return () => clearTimeout(timer);
    // });

    if (null === config) {

        return null;
    }

    // const menus = getMenus(name, config, updateConfig);
    const menus = AppData.getApp(name).getMenus(config);

    return (
        // <Fade show={show}>
            <MenuBar>
                {
                    menus.map((menu) => {
                        const type = menu.getType();
                        const items = menu.getItems();

                        return (
                            <Menu key={type}>
                                {items.map((item, i) => {
                                    const {title, configure, imageSource} = item;

                                    return (
                                        <MenuItem key={i}>
                                            <MenuButton
                                                imageSource={imageSource}
                                                isSelected={menu.getSelectedMenuItem(config) === item}
                                                onSelect={() => {
                                                    // setShow(false)
                                                    // updateConfig(name, config, configure)
                                                    setTimeout(
                                                        ()=> updateConfig(name, config, configure), 200
                                                    )
                                                }}
                                            >
                                                {title}
                                            </MenuButton>
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        );
                    })
                }
            </MenuBar>
        // </Fade>
    )
};

const mapStateToProps = state => ({
    config: getConfig(state),
});

const mapDispatchToProps = dispatch => ({
    updateConfig: (name, config, configure) => {
        config = healConfig(name, {...config, ...configure});
        dispatch(updateConfig(config));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBarContainer)