import defs                               from '../resources/app-definitions.json'
import {isSelected, selectorsAreMatching} from './helpers'

class AppData {
    constructor(data) {
        this.data = data;
    }

    getApp = (name) => {
        for (let i = 0; i < defs.length; i++) {
            if (this.data[i].name === name) {

                return new App(this.data[i]);
            }
        }

        throw new Error('Did not found app definition for "' + name + '"');
    };
}

// @todo extract MenuContainer
class App {
    constructor(data) {
        this.data = data;
    }

    getMapping = (config) => {
        const mappings = this.data.mappings;

        for (let i = 0; i < mappings.length; i++) {
            const map = mappings[i];

            if (selectorsAreMatching(map.selectors, config)) {

                return map;
            }
        }

        throw new Error('Did not found mapping definition for current config.');
    };

    getInitialConfig = () => {

        return this.data.initialConfig;
    };

    getMenus = (config) => this.data.menus.filter((menu) => {

        return selectorsAreMatching(menu.selectors, config);
    }).map(menu => new Menu(menu));

    getMenu = (config, type) => {
        const menus = this.getMenus(config);

        for (let i = 0; i < menus.length; i++) {
            if (menus[i].getType() === type) {

                return menus[i];
            }
        }

        throw new Error('Did not found menu for type  "' + type + '"');
    };
}

class Menu {
    constructor(data) {
        this.data = data;
    }

    getType = () => {

        return this.data.type;
    };

    getItems = () => {

        return this.data.items;
    };

    getSelectedMenuItem = (config) => {
        const {items} = this.data;

        for (let i = 0; i < items.length; i++) {
            if (isSelected(items[i].configure, config)) {

                return items[i];
            }
        }

        return null;
    };
}

export default () => new AppData(defs);