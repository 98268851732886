import React from 'react';
import './menu-bar.scss'

const MenuBar = ({children}) => {

    return (
        <div className="can-menu-bar">
            {children}
        </div>
    );
};

export default MenuBar