import {menuTypes}                        from '../constants'
import createAppData                      from './app-data'

export const AppData = createAppData();

/**
 * If a menu has no selected item (no item selected or invalid configuration value), the first menu item is selected.
 *
 * @param name
 * @param config
 *
 * @return {*}
 */
export const healConfig = (name, config) => {
    const types = [menuTypes.materials];
    // const types = [menuTypes.groups, menuTypes.materials];

    const app = AppData.getApp(name);

    types.forEach((type) => {
        const menu = app.getMenu(config, type);
        if (null === menu.getSelectedMenuItem(config)) {
            const items = menu.getItems();

            config = {
                ...config,
                ...items[0].configure,
            };
        }
    });

    return config;
};