import {useEffect}    from 'react'
import {getStore}     from '../redux-store';
import {updateConfig} from '../stores/app';
import {AppData}      from '../app-data'

export default (name) => {

    useEffect(() => {
        const initialConfig = AppData.getApp(name).getInitialConfig();
        getStore().dispatch(updateConfig(initialConfig))
    }, [name])
}