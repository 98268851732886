import React      from 'react'
import {Provider} from 'react-redux'
import {getStore} from './get-store'

export default ({children}) => {

    return (
        <Provider store={getStore()}>
            {children}
        </Provider>
    )
}