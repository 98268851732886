import React from 'react'
import './viewer.scss'

const Viewer = ({source}) => {

    return (
        <div id="can_viewer">
            <model-viewer id="can_model_viewer"
                          camera-controls environment-image="./3d-envs/city.hdr"
                          exposure="1"
                          shadow-intensity="0"
                          shadow-softness="0"
                          loading="eager"
                          src={source}
                          poster="./images/fahrrad_poster.png"
                          // camera-orbit="0deg 80deg"
                          max-camera-orbit="180deg 90deg"
                          min-camera-orbit="-180deg 0deg"
            />
        </div>
    );

};

export default Viewer