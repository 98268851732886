import React from 'react';
import './menu.scss'

const Menu = ({children}) => {

    return (
        <ul className="can-menu">
            {children}
        </ul>
    );
};

export default Menu