export const menuTypes = {
  groups: 'groups',
  materials: 'materials'
};

export const deepLinkSettings = {
    url: 'jung://jung_app',
    params: {
        configuration: 'configuration'
    }
};