import React            from 'react';
import './App.scss';
import MenuBarContainer from './container/menu-bar-container'
import ViewerContainer  from './container/viewer-container'
import {StoreProvider}  from './redux-store'
import useConfigInit    from './use/use-config-init'
// import DeepLinkContainer from './container/deep-link-container'

function App({name}) {
    useConfigInit(name);

    return (
        <StoreProvider>
            {/*<div className="dummy-menu">MENÜ - LEISTE</div>*/}
            <div id="can_app">
                <div id="can_menus">
                    <MenuBarContainer name={name}/>
                </div>
                <div id="can_3dView">
                    <ViewerContainer name={name}/>
                </div>
            </div>
            {/*<DeepLinkContainer/>*/}
        </StoreProvider>
    );
}

export default App;
