import React        from 'react'
import {connect}    from 'react-redux'
import '@google/model-viewer/dist/model-viewer'
import {AppData}    from '../app-data'
import {getConfig}  from '../stores/app'
import {Viewer}     from '../ui/viewer'

const ViewerContainer = ({name, config}) => {
    if (null === config) {

        return null;
    }

    const {source} = AppData.getApp(name).getMapping(config);

    return (
        <Viewer source={source}/>
    );
};

const mapStateToProps = state => ({
    config: getConfig(state),
});

export default connect(mapStateToProps)(ViewerContainer)