export const selectorsAreMatching = (selectors, config) => {
    if (null === selectors) {

        return true;
    }

    const keys = Object.keys((selectors));
    for (let j = 0; j < keys.length; j++) {
        const key = keys[j];
        if (!config.hasOwnProperty(key)) {

            throw new Error('Key "' + key + '" is not defined.');
        }
        if (selectors[key] !== config[key]) {

            return false;
        }
    }

    return true;
};

export const isSelected = (configure, config) => {

    const keys = Object.keys((configure));
    for (let j = 0; j < keys.length; j++) {
        const key = keys[j];
        if (!config.hasOwnProperty(key)) {

            throw new Error('Key "' + key + '" is not defined.');
        }
        if (configure[key] !== config[key]) {

            return false;
        }
    }

    return true;
};