import React from 'react'
import './menu-button.scss'

const MenuButton = ({isSelected, onSelect, imageSource, children}) => {

    const selectedClass = isSelected ? " can-menu-button-selected" : ""

    return (
        <div className={"can-menu-button" + selectedClass} onClick={onSelect}>
            <img className="can-menu-button-image" src={imageSource} alt=""/>
            <span className="can-menu-button-text">{children}</span>
        </div>
    );
};

export default MenuButton;