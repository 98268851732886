import React from 'react';
import './menu-item.scss'

const MenuItem = ({children}) => {

    return (
        <li className="can-menu-item">
            {children}
        </li>
    );
};

export default MenuItem