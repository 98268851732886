import * as t from './action-types';

const initialState = null

export default function (state = initialState, action) {

    switch (action.type) {

        case t.UPDATE_CONFIG:

            return {
                ...state,
                ...action.config
            };

        default:

            return state;
    }
}