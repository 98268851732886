import {applyMiddleware, createStore} from 'redux'
import reducer                        from '../stores/reducer'
import thunk                          from 'redux-thunk';
import {createLogger}                 from 'redux-logger';

let store = null;

export const getStore = () => {

    if (store === null) {
        // store = createStore(reducer, applyMiddleware(thunk/*, createLogger()*/))
        store = createStore(reducer, applyMiddleware(thunk, createLogger()))
    }

    return store
};